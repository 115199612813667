import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../components/SEO";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const MainDiv = styled.div`
  max-width: 1024px;
  margin: 24px auto;
  padding: 64px;
`;
const P = styled.p`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
  color: #3d4d69;
`;
const H1 = styled.h1`
  margin: 0 0 1.5rem;
  font-family: "Futura PT", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  line-height: 1.25;
  font-size: 42px;
  letter-spacing: 0.42px;
  text-transform: capitalize;
`;
const StyledH2 = styled.h2`
  margin: 0 0 1.5rem;
  text-rendering: optimizeLegibility;
  font-size: 32px;
  line-height: 1.25;
`;
const StyledLi = styled.li`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
`;
const StyledLink = styled(Link)`
  color: ${(props) => (props.sameColor ? `#1f2939` : `#0000EE`)};
  text-decoration: none;
`;
const StyledA = styled.a`
  color: ${(props) => (props.sameColor ? `#1f2939` : `#0000EE`)};
  text-decoration: none;
`;

// markup
const Privacy = ({ location }) => {
  return (
    <>
      <SEO
        title="Terms & Condition"
        description="The terms of services of Receiptmakerly will help you to understand the rules and regulations we have set for the users. Please read before you start using."
        pathname={location.pathname && location.pathname}
      />
      <MainDiv>
        <H1 id="Terms-of-Use">Terms of Use</H1>

        <P>Last updated: 26 SEP 2022</P>

        <P>
          This web page represents our Terms of Use and Sale (“Agreement”)
          regarding our website, located at receiptmakerly.com, and the tools we
          provide you (the “Website” or the “Service”). The terms, “we” and
          “our” as used in this Agreement refer to Receiptmakerly.com website
          and it’s owners.
        </P>

        <P>
          We may amend this Agreement at any time by posting the amended terms
          on our Website. Please, check our terms of use with an interval so
          that you can know if there is any change in our terms of use. You will
          find the latest update date at the end.
        </P>
        <P>
          We refer to this Agreement, our Privacy Policy accessible at
          https://receiptmakerly.com/privacy-policy/, and any other terms,
          rules, or guidelines on our Website collectively as our “Legal Terms.”
          You explicitly and implicitly agree to be bound by our Legal Terms
          each time you access our Website. If you do not wish to be so bound,
          please do not use or access our Website.
        </P>

        <StyledH2 id="limited-license">Limited License</StyledH2>
        <P>
          Receiptmakerly grants you a non-exclusive, non-transferable, revocable
          license to access and use our Website in order for you to make
          purchases of electronic documents and related services through our
          Website, strictly in accordance with our Legal Terms. Receiptmakerly
          offers three paid subscription plans all of which are recurring until
          the customer cancels the billing agreement with us.
        </P>

        <StyledH2 id="copyrights-and-trademarks">
          Copyrights and Trademarks
        </StyledH2>
        <P>
          Unless otherwise noted, all materials including without limitation,
          logos, brand names, images, designs, photographs, video clips, and
          written and other materials that appear as part of our Website are
          copyrights, trademarks, service marks, trade dress, and/or other
          intellectual property whether registered or unregistered
          (“Intellectual Property”) owned, controlled or licensed by
          Receiptmakerly. Our Website as a whole is protected by copyright and
          trade dress. Nothing on our Website should be construed as granting,
          by implication, estoppel or otherwise, any license or right to use any
          Intellectual Property displayed or used on our Website, without the
          prior written permission of the Intellectual Property owner.
          Receiptmakerly aggressively enforces its intellectual property rights
          to the fullest extent of the law. The names and logos of
          Receiptmakerly, may not be used in any way, including in advertising
          or publicity pertaining to the distribution of materials on our
          Website, without prior, written permission from Receiptmakerly.
          Receiptmakerly prohibits the use of any logo of Receiptmakerly or any
          of its affiliates as part of a link to or from any Website unless
          Receiptmakerly approves such link in advance and in writing. Fair use
          of Receiptmakerly’s Intellectual Property requires proper
          acknowledgment. Other product and company names mentioned on our
          Website may be the Intellectual Property of their respective owners.
        </P>

        <StyledH2 id="links-to-third-party-Websites">
          Links to Third-Party Websites
        </StyledH2>
        <P>
          Our Website may contain links to Websites owned or operated by parties
          other than Receiptmakerly. Such links are provided for your reference
          only. Receiptmakerly does not monitor or control outside Websites and
          is not responsible for their content. Receiptmakerly’s inclusion of
          links to an outside Website does not imply any endorsement of the
          material on our Website or unless expressly disclosed otherwise, any
          sponsorship, affiliation, or association with its owner, operator, or
          sponsor, nor does Receiptmakerly’s inclusion of the links imply that
          Receiptmakerly is authorized to use any trade name, trademark, logo,
          legal or official seal, or copyrighted symbol that may be reflected in
          the linked Website.
        </P>

        <StyledH2 id="content-disclaimer">Content Disclaimer</StyledH2>
        <P>
          Postings on our Website are made at such times as Receiptmakerly
          determines at its discretion. You should not assume that the
          information contained on our Website has been updated or otherwise
          contain current information. Receiptmakerly does not review past
          postings to determine whether they remain accurate and information
          contained in such postings may have been superseded. THE INFORMATION
          AND MATERIALS ON OUR WEBSITE ARE PROVIDED FOR YOUR REVIEW IN
          ACCORDANCE WITH THE NOTICES, TERMS AND CONDITIONS SET FORTH HEREIN.
          THESE MATERIALS ARE NOT GUARANTEED OR REPRESENTED TO BE COMPLETE,
          CORRECT, OR UP TO DATE. THESE MATERIALS MAY BE CHANGED FROM TIME TO
          TIME WITHOUT NOTICE.
        </P>

        <StyledH2 id="disclaimer">Disclaimer</StyledH2>
        <P>
          You understand that we offer bill and receipt generator software tool
          for sale through our Website. You acknowledge that we are not creating
          bills or receipts nor do we offer any advice on creating fake bills.
          You acknowledge that we do not endorse any misuse of our
          software.Different Brand logos inside the receipt styles in our blog
          or software [if any] are for demonstration purpose only.
        </P>

        <StyledH2 id="payments-and-fees">Payments and Fees</StyledH2>
        <P>
          Receiptmakerly may save your credit card and/or PayPal information and
          use it for all future charges, which will automatically be charged
          unless you notify us. For recurring fees, Receiptmakerly will charge
          your credit card on the time interval. When the Client uses a Service
          that has a fee, the Client will be charged the current fees, which we
          may change from time to time. Receiptmakerly reserves the right to
          change its billing methods.
        </P>

        <P>
          By filling in your payment information through the Site, you are
          confirming that you are legally entitled to use the means of payment
          tendered. In the case of card payments, you are either the cardholder
          or have the cardholder’s express permission to utilize the card to
          effect payment.
        </P>

        <P>
          The Service is billed in advance weekly, monthly, or yearly. Should
          you find any change or amendment unacceptable, you may opt to cancel
          your membership without however any right to demand a refund for fees
          that have already accrued before such cancellation. All fees are
          exclusive of all taxes, levies, or duties imposed by taxing
          authorities, and you shall be responsible for payment of all such
          taxes, levies, or duties.
        </P>

        <P>
          If a billing cycle starts on date X of a given month it will end on
          date X of the next month irrespective of the number of days in those
          months. Date X is calculated on your first payment. For any upgrade or
          downgrade in plan level, your credit card/payment method that you
          provided will automatically be charged the new rate for the rest of
          your billing cycle and the previously charged amount will not be
          refunded. From next time, the billing cycle charges will be based on
          your changed plan.{" "}
        </P>

        <P>
          There will be no refunds or credits for partial months of Service, or
          refunds for months unused with an open account. Receiptmakerly may
          choose to provide refunds or credits for partial usage but is not
          legally bound/liable to do so.
        </P>

        <P>
          We may refuse to process a transaction for any reason or refuse the
          Service to anyone at any time at our sole discretion. We will not be
          liable to you or any third party because of refusing or suspending any
          transaction after processing has begun.
        </P>

        <P>
          Unless otherwise stated, all fees and payments are quoted in U.S.
          Dollars. The User is responsible for paying all fees, payments, and
          applicable taxes associated with our Site and Services.
        </P>

        <P>
          If you cancel your subscription or your account is deleted,
          Receiptmakerly will not refund any fees that may have accrued before
          the termination
        </P>
        <StyledH2 id="refunds">Refunds</StyledH2>
        <P>
          Since there is no trial of the software, Receiptmakerly offers 14 days
          refund policy.If you do not find the product to serve your purpose or
          it does not work like you intended it to be.
        </P>
        <P>
          You also understand that while asking for refund you might need to
          justify the reason behind your refund request and we have the full
          right to interpet whether you are eligible or not.{" "}
        </P>
        <P>Any refund beyond 14 days of payment will not be accounted for.</P>
        <StyledH2 id="No Warranties">
          No Warranties; Exclusion of Liability; Indemnification
        </StyledH2>

        <P>
          OUR WEBSITE IS OPERATED BY RECEIPTMAKERLY ON AN “AS IS,” “AS
          AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND.
          TO THE FULLEST EXTENT PERMITTED BY LAW, RECEIPTMAKERLY SPECIFICALLY
          DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING ALL
          IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT FOR OUR WEBSITE AND ANY
          CONTRACTS AND SERVICES YOU PURCHASE THROUGH IT. RECEIPTMAKERLY SHALL
          NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS
          IN THE CONTENT OF OUR WEBSITE, FOR CONTRACTS OR SERVICES SOLD THROUGH
          OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION WITH OUR
          WEBSITE, OR FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER
          DAMAGE YOU MAY INCUR IN CONNECTION WITH OUR WEBSITE. YOUR USE OF OUR
          WEBSITE AND ANY CONTRACTS OR SERVICES ARE AT YOUR OWN RISK. IN NO
          EVENT SHALL EITHER RECEIPTMAKERLY OR THEIR AGENTS BE LIABLE FOR ANY
          DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
          DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OUR
          WEBSITE, CONTRACTS, AND SERVICES PURCHASED THROUGH OUR WEBSITE, THE
          DELAY OR INABILITY TO USE OUR WEBSITE OR OTHERWISE ARISING IN
          CONNECTION WITH OUR WEBSITE OR RELATED SERVICES, WHETHER BASED ON
          CONTRACT OR, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE
          POSSIBILITY OF ANY SUCH DAMAGES. IN NO EVENT SHALL RECEIPTMAKERLY’S
          LIABILITY FOR ANY DAMAGE CLAIM EXCEED THE AMOUNT PAID BY YOU TO
          RECEIPTMAKERLY FOR THE TRANSACTION GIVING RISE TO SUCH DAMAGE CLAIM.
        </P>

        <P>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT
          APPLY TO YOU.
        </P>

        <P>
          WITHOUT LIMITING THE FOREGOING, RECEIPTMAKERLY DOES NOT REPRESENT OR
          WARRANT THAT THE INFORMATION ON THE WEBSITE IS ACCURATE, COMPLETE,
          RELIABLE, USEFUL, TIMELY, OR CURRENT OR THAT OUR WEBSITE WILL OPERATE
          WITHOUT INTERRUPTION OR ERROR.
        </P>

        <P>
          YOU AGREE THAT AT ALL TIMES, YOU WILL LOOK TO ATTORNEYS FROM WHOM YOU
          PURCHASE SERVICES FOR ANY CLAIMS OF ANY NATURE, INCLUDING LOSS,
          DAMAGE, OR WARRANTY. RECEIPTMAKERLY AND THEIR RESPECTIVE AFFILIATES
          MAKE NO REPRESENTATION OR GUARANTEES ABOUT ANY SERVICES OFFERED
          THROUGH OUR WEBSITE.
        </P>

        <P>
          RECEIPTMAKERLY MAKES NO REPRESENTATION THAT CONTENT PROVIDED ON OUR
          WEBSITE, OR RELATED SERVICES ARE APPLICABLE OR APPROPRIATE FOR USE IN
          ALL JURISDICTIONS.
        </P>
        <StyledH2 id="Indemnity">Indemnification</StyledH2>

        <P>
          You agree to defend, indemnify and hold Receiptmakerly harmless from
          and against any and all claims, damages, costs, and expenses,
          including attorneys’ fees, arising from or related to your use of our
          Website or any bills, invoices, or receipts you created using it.
        </P>
      </MainDiv>
    </>
  );
};

export default Privacy;
